/************************************************
  File: src/components/LoginPage/LoginPage.js
*************************************************/
import React, { useState } from 'react';
import axios from 'axios';
import './LoginPage.css';
import HeaderImage from '../../assets/Images/Header.png';
import LottieLoader from '../LoadingSpinner/LottieLoader'; // ✅ NEW IMPORT

function LoginPage({ onLoginSuccess, onGuestLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // ✅ NEW STATE

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // ✅ Start loading

    try {
      const response = await axios.post('https://edugroove-backend.onrender.com/api/login', {
        email,
        password,
      });

      onLoginSuccess(response.data); 
    } catch (err) {
      setError('Invalid email or password');
      console.log(err);
    } finally {
      setIsLoading(false); // ✅ Stop loading
    }
  };

  const handleGuest = () => {
    if (onGuestLogin) {
      onGuestLogin();
    }
  };

  // ✅ Show spinner while loading
  if (isLoading) {
    return <LottieLoader message="Loading Groovesheets" />;
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-container">
        <img src={HeaderImage} alt="Header" className="login-header-image" />

        <h2>Login</h2>

        {error && <p className="error">{error}</p>}

        <form onSubmit={handleLogin}>
          <div>
            <label>Email</label>
            <input 
              type="text" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button type="submit">Login</button>
        </form>

        <button className="guest-button" onClick={handleGuest}>
          Continue as Guest
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
