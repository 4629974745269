// src/components/LoadingSpinner/LottieLoader.js
import React from 'react';
import Lottie from 'lottie-react';
import LoaderAnimation from '../../assets/Images/Loader.json';
import './LoadingSpinner.css'; // Make sure this is imported

const LottieLoader = ({ message = "Loading..." }) => {
  return (
    <div className="loading-overlay">
      <div className="lottie-wrapper">
        <Lottie
          animationData={LoaderAnimation}
          loop={true}
          className="lottie-animation"
        />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default LottieLoader;
