import React, { useEffect, useState, useMemo } from 'react';
import './ImageLibraryModal.css';
import LottieLoader from '../LoadingSpinner/LottieLoader';

const ImageLibraryModal = ({ onClose, onImageSelect }) => {
  const categories = useMemo(() => [
    "All", "Transport", "Fruit", "Animals", "Jobs", "Dinosaurs", "Shapes", 
    "School", "Weather", "Diddy Bots", "Diddy Bots Assets", "Emotions", "Places", "Verbs", 
    "Sports", "Clothing", "Vegetables", "Science", "Food & Cutlery", "Home & Garden", 
    "Flags", "Body", "Family", "Toys", "Jigsaws", "Special Days"
  ], []);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [images, setImages] = useState([]);
  const [imageCache, setImageCache] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [preserveWhiteFill, setPreserveWhiteFill] = useState(true); // ✅ New checkbox state  

  useEffect(() => {
    if (imageCache[selectedCategory]) {
      setImages(imageCache[selectedCategory]);
    } else {
      fetchImages(selectedCategory);
    }
  }, [selectedCategory, imageCache]);

  const fetchImages = async (category) => {
    setIsLoading(true);
    try {
      let images = [];
      if (category === "All") {
        const categoriesToFetch = categories.filter(cat => cat !== "All");
        const results = await Promise.all(
          categoriesToFetch.map(async (cat) => {
            if (imageCache[cat]) return imageCache[cat];
            const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${cat}/index.json`);
            const categoryImages = await response.json();
            return categoryImages.map((image) => ({ ...image, category: cat }));
          })
        );
        images = results.flat();
      } else {
        const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${category}/index.json`);
        images = (await response.json()).map((image) => ({ ...image, category }));
      }

      setImageCache(prevCache => ({ ...prevCache, [category]: images }));
      setImages(images);
    } catch (error) {
      console.error("Failed to load images", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredImages = useMemo(() => {
    return images.filter(image =>
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [images, searchTerm]);

  return (
    <div className="image-library-modal-overlay">
      <div className="image-library-modal">
        <div className="image-library-header">
          <h2>Image Library</h2>
          <button onClick={onClose} className="image-library-close-btn" aria-label="Close Modal">
            ×
          </button>
        </div>

        <div className="image-library-search-bar">
          <input
            type="text"
            placeholder="Search images..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* ✅ New checkbox toggle for Fill */}
        <div style={{ margin: '10px 0', paddingLeft: '10px' }}>
          <label style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              checked={preserveWhiteFill}
              onChange={() => setPreserveWhiteFill(!preserveWhiteFill)}
              style={{ marginRight: '6px' }}
            />
            Fill
          </label>
        </div>

        <div className="image-library-content">
          <div className="image-library-sidebar">
            <ul>
              {categories.map((category) => (
                <li key={category}>
                  <button
                    className={`image-library-category-btn ${selectedCategory === category ? 'active' : ''}`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="image-library-grid">
            {isLoading ? (
              <div className="modal-loader-container-wrapper">
                <div className="modal-loader-container">
                  <LottieLoader message="Loading Images..." />
                </div>
              </div>
            ) : filteredImages.length > 0 ? (
              filteredImages.map((image, index) => (
                <div
                  key={index}
                  className="image-library-card"
                  onClick={async () => {
                    const url = `${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`;
                  
                    if (!preserveWhiteFill && image.file.endsWith('.svg')) {
                      try {
                        const response = await fetch(url);
                        const svgText = await response.text();
                  
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
                        const svgEl = svgDoc.documentElement;
                  
                        // Add xmlns if missing
                        if (!svgEl.getAttribute("xmlns")) {
                          svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                        }
                  
                        // Remove white from fill attributes and inline styles
                        const elements = svgDoc.querySelectorAll('[fill], [style]');
                        elements.forEach(el => {
                          const fill = el.getAttribute("fill");
                          const style = el.getAttribute("style");
                  
                          if (fill && ["#fff", "#ffffff", "white"].includes(fill.toLowerCase())) {
                            el.setAttribute("fill", "transparent");
                          }
                  
                          if (style && /fill\s*:\s*(white|#fff|#ffffff)/gi.test(style)) {
                            el.setAttribute("style", style.replace(/fill\s*:\s*(white|#fff|#ffffff)/gi, "fill:transparent"));
                          }
                        });
                  
                        // ✅ Remove white fill from <style> tag content
                        const styleTags = svgDoc.querySelectorAll("style");
                        styleTags.forEach(tag => {
                          tag.textContent = tag.textContent.replace(
                            /fill\s*:\s*(white|#fff|#ffffff)/gi,
                            "fill:transparent"
                          );
                        });
                  
                        const serializer = new XMLSerializer();
                        const fullSVG = serializer.serializeToString(svgDoc);
                        const encoded = encodeURIComponent('<?xml version="1.0"?>' + fullSVG);
                        const dataUrl = `data:image/svg+xml;charset=utf-8,${encoded}`;
                  
                        onImageSelect({
                          src: dataUrl,
                          name: image.name,
                          width: 100,
                          height: 100,
                        });
                      } catch (error) {
                        console.error("Error processing SVG:", error);
                      }
                    } else {
                      onImageSelect({
                        src: url,
                        name: image.name,
                        width: 100,
                        height: 100,
                      });
                    }
                  }}                
                  
                  
                >
                  <p className="image-library-name">{image.name}</p>
                  <img
                    src={`${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`}
                    alt={image.name}
                    className="image-library-image"
                  />
                </div>
              ))
            ) : (
              <p>No images match your search.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageLibraryModal;
