import React, { useContext } from 'react';
import './TopMenuBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faCopy,
  faPaste,
  faArrowUp,
  faArrowDown,
  faEdit,
  faLock,
  faUnlock,
  faMagicWandSparkles,
  faUserShield, // <-- New Admin icon
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; 
import { UserContext } from '../../context/UserContext';

const TopMenuBar = ({
  undo,
  redo,
  deleteSelectedShapes,
  selectedShapeIds,
  copySelectedShapes,
  pasteCopiedShapes,
  canPaste,
  bringForward,
  sendBack,
  isEditMode,
  toggleEditMode,
  areAllSelectedLocked,
  lockSelectedShapes,
  copyToAllPages,
}) => {
  // Grab currentUser from context
  const { currentUser } = useContext(UserContext);

  // For navigation
  const navigate = useNavigate();

  return (
    <div className="top-menu">
      {/* Admin Panel Button (only visible if currentUser is admin) */}
      {currentUser && currentUser.isAdmin && (
        <button onClick={() => navigate('/admin')}>
          <FontAwesomeIcon icon={faUserShield}  />          
        </button>
      )}

      {/* Undo */}
      <button onClick={undo} title="Undo (Ctrl+Z)">
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>

      {/* Redo */}
      <button onClick={redo} title="Redo (Ctrl+Shift+Z)">
        <FontAwesomeIcon icon={faArrowRight} />
      </button>

      {/* Delete */}
      <button
        onClick={deleteSelectedShapes}
        disabled={selectedShapeIds.length === 0}
        title="Delete (Del)"
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>

      {/* Copy */}
      <button
        onClick={copySelectedShapes}
        disabled={selectedShapeIds.length === 0}
        title="Copy (Ctrl+C)"
      >
        <FontAwesomeIcon icon={faCopy} />
      </button>

      {/* Paste */}
      <button 
        onClick={() => {
          const fakeEvent = {
            preventDefault: () => {},
            target: { tagName: '', isContentEditable: false }
          };
          window.dispatchEvent(new ClipboardEvent('paste')); // ✅ Trigger real paste
        }}
        title="Paste (Ctrl+V)"
      >
        <FontAwesomeIcon icon={faPaste} />
      </button>


      {/* Bring Forward */}
      <button
        onClick={bringForward}
        disabled={selectedShapeIds.length === 0}
        title="Bring Forward (Up Arrow)"
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>

      {/* Send Back */}
      <button
        onClick={sendBack}
        disabled={selectedShapeIds.length === 0}
        title="Send Back (Down Arrow)"
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </button>

      {/* Copy to All Pages (magic wand icon) */}
      <button
        onClick={copyToAllPages}
        disabled={selectedShapeIds.length === 0}
        title="Copy to All Pages"
        className="copy-all-pages-button"
      >
        <FontAwesomeIcon icon={faMagicWandSparkles} />
      </button>

      {/* Lock / Unlock Selected Shapes */}
      <button
        onClick={lockSelectedShapes}
        disabled={!isEditMode || selectedShapeIds.length === 0}
        title="Lock/Unlock Selected Shapes"
        className={areAllSelectedLocked ? 'locked' : ''}
      >
        <FontAwesomeIcon icon={areAllSelectedLocked ? faLock : faUnlock} />
      </button>

      {/* Edit Mode Button */}
      <button
        onClick={toggleEditMode}
        title="Edit Mode"
        className={isEditMode ? 'edit-mode-active' : ''}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </div>
  );
};

export default TopMenuBar;