// frontend/src/components/CanvasArea/CanvasArea.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import './CanvasArea.css';
import {
  Stage,
  Layer,
  Rect,
  Circle,
  RegularPolygon,
  Star,
  Transformer,
  Image,
  Text,
  Line,
} from 'react-konva';
import useImage from 'use-image';
import useFontLoader from '../../hooks/useFontLoader';
import pencilCursor from '../../assets/cursors/pencil.cur';

const ImageShape = ({ shape, onLoad, ...props }) => {
  const [image, status] = useImage(shape.src, 'Anonymous');

  useEffect(() => {
    if (status === 'loaded' && onLoad) {
      onLoad();
    }
  }, [status, onLoad]);

  if (status === 'loading') {
    return <Text text="Loading..." x={shape.x} y={shape.y} />;
  }

  if (status === 'error') {
    return (
      <Text
        text="Failed to load image"
        x={shape.x}
        y={shape.y}
        fill="red"
      />
    );
  }

  return (
    <Image
      image={image}
      width={shape.width}
      height={shape.height}
      offsetX={shape.width / 2}
      offsetY={shape.height / 2}
      opacity={shape.opacity}
      {...props}
    />
  );
};

const CanvasArea = ({
  pages,
  activePage,
  selectedTool,
  selectedShapeIds,
  setSelectedShapeIds,
  selectionRect,
  setSelectionRect,
  isSelecting,
  setIsSelecting,
  stageRef,
  transformerRef,
  panOffset,
  scale,
  drawing,
  setDrawing,
  handleStageMouseDown,
  handleStageMouseMove,
  handleStageMouseUp,
  isShiftPressed,
  isEditingText,
  editingTextPosition,
  editingTextId,
  editingTextValue,
  updateTextContentHandler,
  setIsEditingText,
  textProperties,
  handleShapeClick,
  handleTextDoubleClick,
  handleShapeDragStart,
  handleShapeDragEnd,
  handleTransformEnd,
  isGridVisible,
  isQuartersGridVisible,
  isThirdsGridVisible,
  isEditMode,
  pushToUndoStack,
  isExporting,
}) => {
  const initialPositionsRef = useRef({});
  const [imageLoadCounter, setImageLoadCounter] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const transformStartedRef = useRef(false);

  const onImageLoad = useCallback(() => {
    setImageLoadCounter((prev) => prev + 1);
  }, []);

  const handleMouseDownInternal = (e) => {
    if (selectedTool === 'hand') {
      setIsDragging(true);
    }
    handleStageMouseDown(e);
  };

  const handleMouseMoveInternal = (e) => {
    handleStageMouseMove(e);
  };

  const handleMouseUpInternal = (e) => {
    if (selectedTool === 'hand') {
      setIsDragging(false);
    }
    handleStageMouseUp(e);
  };

  const handleTransformEndSnapping = (shapeId, node) => {
    const shape = pages[activePage].shapes.find(
      (s) => s.id.toString() === shapeId
    );
    if (shape && shape.locked) {
      return;
    }

    if (!transformStartedRef.current) {
      transformStartedRef.current = true;
      pushToUndoStack(); // ✅ Push only once per group transform
    }
    

    if (isShiftPressed) {
      const rotation = node.rotation();
      const snappedRotation = Math.round(rotation / 45) * 45;

      const updatedProps = {
        rotation: snappedRotation,
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        x: node.x(),
        y: node.y(),
      };
      handleTransformEnd(shapeId, updatedProps);
    } else {
      const updatedProps = {
        rotation: node.rotation(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        x: node.x(),
        y: node.y(),
      };
      handleTransformEnd(shapeId, updatedProps);
    }
  };

  const createDragBoundFunc = (shapeId) => {
    return (pos) => {
      if (!isShiftPressed) {
        return pos;
      }

      const initialPos = initialPositionsRef.current[shapeId];
      if (!initialPos) {
        return pos;
      }

      const dx = pos.x - initialPos.x;
      const dy = pos.y - initialPos.y;

      if (Math.abs(dx) > Math.abs(dy)) {
        return { x: pos.x, y: initialPos.y };
      } else {
        return { x: initialPos.x, y: pos.y };
      }
    };
  };

  const handleDragStartInternal = (shapeId) => {
    handleShapeDragStart(shapeId);

    const shape = pages[activePage].shapes.find(
      (s) => s.id.toString() === shapeId
    );
    if (shape) {
      initialPositionsRef.current[shapeId] = { x: shape.x, y: shape.y };
    }
  };

  const handleDragEndInternal = (shapeId, newPos) => {
    handleShapeDragEnd({
      target: {
        id: shapeId,
        x: newPos.x,
        y: newPos.y,
      },
    });

    delete initialPositionsRef.current[shapeId];
  };

  const validateSelectedShapeIds = () => {
    const validIds = pages[activePage].shapes.map((shape) =>
      shape.id.toString()
    );
    const filteredSelectedIds = selectedShapeIds.filter((id) =>
      validIds.includes(id)
    );

    if (filteredSelectedIds.length !== selectedShapeIds.length) {
      console.warn(
        'Some selectedShapeIds do not correspond to any shapes and have been removed:',
        selectedShapeIds.filter((id) => !validIds.includes(id))
      );
      setSelectedShapeIds(filteredSelectedIds);
    }
  };

  useEffect(() => {
    validateSelectedShapeIds();
  }, [pages, activePage, selectedShapeIds]);

  useEffect(() => {
    if (transformerRef.current) {
      const stage = stageRef.current;
      const selectedNodes = selectedShapeIds
        .map((id) => ({ id, node: stage.findOne(`#${id}`) }))
        .filter(({ id, node }) => {
          if (!node) {
            console.warn(`No node found with id: ${id}`);
            return false;
          }
          const shape = pages[activePage].shapes.find(
            (s) => s.id.toString() === node.id()
          );
          return shape && !shape.locked;
        })
        .map(({ node }) => node);

      transformerRef.current.nodes(selectedNodes);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [
    selectedShapeIds,
    pages,
    activePage,
    selectedTool,
    isEditMode,
    stageRef,
    transformerRef,
    imageLoadCounter,
    isExporting,
  ]);

  // Get all fonts used on this page
  const fontsUsed = Array.from(
    new Set(
      pages[activePage].shapes
        .filter((shape) => shape.type === 'text' && shape.fontFamily)
        .map((shape) => shape.fontFamily)
    )
  );

  // Load them
  const fontsReady = useFontLoader(fontsUsed);

  // Don’t render until fonts are loaded
  if (!fontsReady) return null;


  if (!pages?.[activePage]?.shapes) {
    return null;
  }

  const generateSimpleGridLines = (width, height, gridSize = 50) => {
    const lines = [];
    for (let i = 0; i <= width; i += gridSize) {
      lines.push(
        <Line
          key={`simple-v-${i}`}
          points={[i, 0, i, height]}
          stroke="#e0e0e0"
          strokeWidth={1}
        />
      );
    }
    for (let j = 0; j <= height; j += gridSize) {
      lines.push(
        <Line
          key={`simple-h-${j}`}
          points={[0, j, width, j]}
          stroke="#e0e0e0"
          strokeWidth={1}
        />
      );
    }
    return lines;
  };

  const generateQuartersGridLines = (width, height) => {
    const lines = [];
    const quarterWidth = width / 4;
    const quarterHeight = height / 4;

    for (let i = 1; i <= 3; i++) {
      const x = quarterWidth * i;
      lines.push(
        <Line
          key={`quarters-v-${i}`}
          points={[x, 0, x, height]}
          stroke="#ff0000"
          strokeWidth={2}
        />
      );
    }

    for (let j = 1; j <= 3; j++) {
      const y = quarterHeight * j;
      lines.push(
        <Line
          key={`quarters-h-${j}`}
          points={[0, y, width, y]}
          stroke="#ff0000"
          strokeWidth={2}
        />
      );
    }

    return lines;
  };

  const generateThirdsGridLines = (width, height) => {
    const lines = [];
    const thirdWidth = width / 3;
    const thirdHeight = height / 3;

    for (let i = 1; i <= 2; i++) {
      const x = thirdWidth * i;
      lines.push(
        <Line
          key={`thirds-v-${i}`}
          points={[x, 0, x, height]}
          stroke="#0000ff"
          strokeWidth={2}
        />
      );
    }

    for (let j = 1; j <= 2; j++) {
      const y = thirdHeight * j;
      lines.push(
        <Line
          key={`thirds-h-${j}`}
          points={[0, y, width, y]}
          stroke="#0000ff"
          strokeWidth={2}
        />
      );
    }

    return lines;
  };

  const crosshairTools = ['shape', 'line'];

  const getCursorStyle = () => {
    if (selectedTool === 'hand') {
      return isDragging ? 'grabbing' : 'grab';
    } else if (selectedTool === 'pencil') {
      return `url(${pencilCursor}), auto`;
    } else if (selectedTool === 'text') {
      return 'text';
    } else if (crosshairTools.includes(selectedTool)) {
      return 'crosshair';
    } else {
      return 'default';
    }
  };

  const selectedShapes = selectedShapeIds.map((id) =>
    pages[activePage].shapes.find((shape) => shape.id.toString() === id)
  );

  const includesLine = selectedShapes.some(
    (shape) => shape && shape.type === 'line'
  );

  const transformerProps = {};

  if (includesLine) {
    transformerProps.ignoreStroke = false;
  } else {
    transformerProps.ignoreStroke = true;
  }

  return (
    <div className="canvas-wrapper">
      <div
        id="canvas-container"
        className="canvas-area"
        style={{
          transform: `translate(${panOffset.x}px, ${panOffset.y}px) scale(${scale})`,
          transformOrigin: 'center center',
          backgroundColor: pages[activePage].backgroundColor,
          width: pages[activePage].width,
          height: pages[activePage].height,
          position: 'relative',
          overflow: 'hidden',
          cursor: getCursorStyle(),
        }}
      >
        <Stage
          width={pages[activePage].width}
          height={pages[activePage].height}
          onMouseDown={handleMouseDownInternal}
          onMouseMove={handleMouseMoveInternal}
          onMouseUp={handleMouseUpInternal}
          ref={stageRef}
        >
          {isGridVisible && (
            <Layer>
              {generateSimpleGridLines(
                pages[activePage].width,
                pages[activePage].height
              )}
            </Layer>
          )}

          {isQuartersGridVisible && (
            <Layer>
              {generateQuartersGridLines(
                pages[activePage].width,
                pages[activePage].height
              )}
            </Layer>
          )}

          {isThirdsGridVisible && (
            <Layer>
              {generateThirdsGridLines(
                pages[activePage].width,
                pages[activePage].height
              )}
            </Layer>
          )}

          <Layer>
            {pages[activePage].shapes.map((shape) => {
              const isMultipleSelected = selectedShapeIds.length > 1;
              const isShapeLocked = shape.locked;

              const isDraggable =
                !isShapeLocked && selectedTool === 'select';

              shape.strokeEnabled =
                shape.strokeEnabled !== undefined
                  ? shape.strokeEnabled
                  : true;
              shape.fillEnabled =
                shape.fillEnabled !== undefined
                  ? shape.fillEnabled
                  : true;

              const fill =
                shape.fillEnabled && shape.type !== 'image'
                  ? shape.fillColor || shape.fill || '#000000'
                  : null;
              const stroke =
                shape.strokeEnabled &&
                shape.type !== 'image' &&
                shape.type !== 'text'
                  ? shape.strokeColor || '#000000'
                  : null;

              const commonProps = {
                key: shape.id,
                id: shape.id.toString(),
                x: shape.x || 0,
                y: shape.y || 0,
                ...(fill && { fill }),
                ...(stroke && { stroke }),
                strokeWidth: shape.strokeWidth,
                rotation: shape.rotation || 0,
                scaleX: shape.scaleX || 1,
                scaleY: shape.scaleY || 1,
                opacity: shape.opacity || 1,
                draggable: isDraggable,
                dash: shape.dashEnabled ? shape.dash : [],
                onClick: (e) =>
                  handleShapeClick(e, shape.id.toString()),
                onTap: (e) =>
                  handleShapeClick(e, shape.id.toString()),
                onDblClick:
                  shape.type === 'text'
                    ? () =>
                        handleTextDoubleClick(shape.id.toString())
                    : undefined,
                onTransformEnd: (e) => {
                  const node = e.target;
                  handleTransformEndSnapping(
                    shape.id.toString(),
                    node
                  );
                },
                onTransform: () => {},
                dragBoundFunc: isDraggable
                  ? createDragBoundFunc(shape.id.toString())
                  : undefined,
                onDragStart: isDraggable
                  ? () =>
                      handleDragStartInternal(shape.id.toString())
                  : undefined,
                onDragEnd: isDraggable
                  ? (e) =>
                      handleDragEndInternal(shape.id.toString(), {
                        x: e.target.x(),
                        y: e.target.y(),
                      })
                  : undefined,
                listening: !(shape.locked && !isEditMode),
              };

              const shapeSpecificProps = {};

              if (
                [
                  'rect',
                  'roundedRect',
                  'circle',
                  'triangle',
                  'pentagon',
                  'hexagon',
                  'octagon',
                  'star',
                ].includes(shape.type)
              ) {
                shapeSpecificProps.strokeScaleEnabled = false;
              }

              switch (shape.type) {
                case 'rect':
                  return (
                    <Rect
                      {...commonProps}
                      {...shapeSpecificProps}
                      width={shape.width}
                      height={shape.height}
                    />
                  );
                case 'roundedRect':
                  return (
                    <Rect
                      {...commonProps}
                      {...shapeSpecificProps}
                      width={shape.width}
                      height={shape.height}
                      cornerRadius={
                        shape.cornerRadius || 10
                      }
                    />
                  );
                case 'circle':
                  return (
                    <Circle
                      {...commonProps}
                      {...shapeSpecificProps}
                      radius={shape.radius}
                    />
                  );
                case 'triangle':
                  return (
                    <RegularPolygon
                      {...commonProps}
                      {...shapeSpecificProps}
                      sides={3}
                      radius={shape.radius}
                      rotation={shape.rotation || 0}
                    />
                  );
                case 'pentagon':
                  return (
                    <RegularPolygon
                      {...commonProps}
                      {...shapeSpecificProps}
                      sides={5}
                      radius={shape.radius}
                    />
                  );
                case 'hexagon':
                  return (
                    <RegularPolygon
                      {...commonProps}
                      {...shapeSpecificProps}
                      sides={6}
                      radius={shape.radius}
                    />
                  );
                case 'octagon':
                  return (
                    <RegularPolygon
                      {...commonProps}
                      {...shapeSpecificProps}
                      sides={8}
                      radius={shape.radius}
                    />
                  );
                case 'star':
                  return (
                    <Star
                      {...commonProps}
                      {...shapeSpecificProps}
                      numPoints={shape.numPoints || 5}
                      innerRadius={
                        shape.innerRadius > 0
                          ? shape.innerRadius
                          : shape.outerRadius / 2
                      }
                      outerRadius={shape.outerRadius}
                    />
                  );
                case 'line':
                  return (
                    <Line
                      {...commonProps}
                      points={shape.points}
                      stroke={shape.strokeColor}
                      strokeWidth={shape.strokeWidth}
                      lineCap="round"
                      lineJoin="round"
                      dash={
                        shape.dashEnabled ? shape.dash : []
                      }
                      opacity={shape.opacity}
                    />
                  );
                  case 'pencil':
                    const { stroke, ...commonWithoutStroke } = commonProps;
                    return (
                      <Line
                        {...commonWithoutStroke}
                        points={shape.points}
                        stroke={shape.strokeColor} // ✅ Always use this
                        strokeWidth={shape.strokeWidth}
                        lineCap={shape.lineCap}
                        lineJoin={shape.lineJoin}
                        tension={shape.tension}
                        dash={shape.dashEnabled ? shape.dash : []}
                        opacity={shape.opacity}
                      />
                    );                  
                case 'image':
                  return (
                    <ImageShape
                      {...commonProps}
                      shape={shape}
                      onLoad={onImageLoad}
                    />
                  );
                case 'text':
                  return (
                    <Text
                      {...commonProps}
                      text={shape.text}
                      fontSize={shape.fontSize}
                      fontFamily={shape.fontFamily}
                      fill={shape.fill}
                      fontStyle={`${
                        shape.bold ? 'bold' : ''
                      } ${shape.italic ? 'italic' : ''}`}
                      decoration={
                        shape.underline ? 'underline' : ''
                      }
                      opacity={shape.opacity}
                      align={shape.align || 'left'} // ✅ Pass this, without setting width
                    />
                  );
                default:
                  return null;
              }
            })}

            {selectionRect && !isExporting && (
              <Rect
                x={Math.min(
                  selectionRect.x,
                  selectionRect.x + selectionRect.width
                )}
                y={Math.min(
                  selectionRect.y,
                  selectionRect.y + selectionRect.height
                )}
                width={Math.abs(selectionRect.width)}
                height={Math.abs(selectionRect.height)}
                fill="rgba(0, 161, 255, 0.3)"
                stroke="#00A1FF"
                dash={[4, 4]}
              />
            )}

            {!isExporting && selectedShapeIds.length > 0 && (
              <Transformer
              ref={transformerRef}
              {...transformerProps}
              onTransformEnd={() => {
                transformStartedRef.current = false; // ✅ Reset after transform is done
              }}
            />
            
            )}
          </Layer>
        </Stage>

        {isEditingText && editingTextId && (
          <input
            type="text"
            value={editingTextValue}
            onChange={(e) =>
              updateTextContentHandler(e.target.value)
            }
            onBlur={() => setIsEditingText(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsEditingText(false);
              }
            }}
            style={{
              position: 'absolute',
              top: editingTextPosition.y + panOffset.y,
              left: editingTextPosition.x + panOffset.x,
              fontSize: textProperties.fontSize,
              fontFamily: textProperties.fontFamily,
              fontWeight: textProperties.bold
                ? 'bold'
                : 'normal',
              fontStyle: textProperties.italic
                ? 'italic'
                : 'normal',
              textDecoration: textProperties.underline
                ? 'underline'
                : 'none',
              color: textProperties.fill,
              border: '1px solid #ddd',
              padding: '2px',
              background: 'transparent',
              outline: 'none',
              pointerEvents: 'auto',
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
            }}
            autoFocus
          />
        )}
      </div>
    </div>
  );
};

export default CanvasArea;
